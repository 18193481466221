<template>
  <CartInfoCard
    :infoValue="value"
    :text="text"
    :hideEdit="true"
    icon="$event"
    :editable="editable"
    :isCheckout="isCheckout"
    @edit="edit"
  />
</template>
<script>
import CartInfoCard from "./CartInfoCard";
export default {
  name: "CartInfoTimeslotCard",
  props: {
    shippingAddress: { type: Object, required: true },
    timeslot: { type: Object, required: true },
    editable: { type: Boolean, default: false },
    isCheckout: { type: Boolean, default: false },
    value: { type: String, required: true }
  },
  components: { CartInfoCard },
  computed: {
    text() {
      return this.$t(
        "navbar.address." +
          this.shippingAddress.addressTypeId +
          ".timeslotButtonTitle"
      );
    }
  },
  methods: {
    edit() {
      if (this.editable) {
        this.$emit("edit");
      }
    }
  }
};
</script>
